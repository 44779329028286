@import
  "minima/skins/dark",
  "minima/initialize";

.post-read-time {
  @extend .post-meta;
  font-weight: bold;
}

$spacing: 0.25rem;

.site-footer {
  padding-block: calc($spacing * 4);
}

.contact-list {
  margin-bottom: 0;
}

.footer-col {
  margin-bottom: 0;
}

.footer-col:last-child {
  padding-left: 0;
}
